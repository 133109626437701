import React, { useEffect, useRef, useState } from 'react';
import TouchCarousel, { clamp } from 'react-touch-carousel';
import touchWithMouseHOC from 'react-touch-carousel/lib/touchWithMouseHOC';
import { inject, observer } from 'mobx-react';
import {
  DefaultIconWrapper,
  MethodFormItemElement,
  PaymentRadioGroup,
	CarouselRadioInfoFeePercent,
} from '../../../FastPaymentFlow/FastPaymentFlow.style';
import NonPassiveTouchTarget from 'common/components/NonPassiveTouchTarget/NonPassiveTouchTarget';
import {
  CarouselCardInner,
  CarouselMethodImageWrapper, CarouselMethodName,
  MobileRadioButton
} from '../../../FastPaymentFlow/FastPaymentFlowMethods.style';
import { PAYMENT_METHOD_DEFAULT_ICON } from 'common/constants/paymentMethodDefaultIcon';
import DefaultPaymentMethodIcon from 'common/assets/renderedSvgIcons/DefaulPaymentMethodIcon';
import { roundTo } from 'common/utils/roundNumber.util';
import { validateUrl } from 'common/utils/urlValidation';
import { useTranslation } from 'react-i18next';
import { replaceColon } from 'common/utils/text.util';

const MethodsCarouselComponent = (props) => {
  const { appStore, methods, sortMethods } = props;

	const { t } = useTranslation();

  /**
   * Carousel element ref.
   */
  const carouselRef = useRef(null);

  /**
   * Local state for start transaction
   */
  const [transactionStarted , setTransactionStarted] = useState(false);

  /**
   * Re-render component on transaction started state change
   */
  useEffect(() => {
    setTransactionStarted(!transactionStarted);
  }, [appStore.getIsTransactionStarted])

  /**
   * Methods carousel parameters.
   */
  const query = window.location.search?.slice(1);
  const enableLoop = /\bloop\b/.test(query);
  const carouselWidth = clamp(window.innerWidth, 0, 320);
  const cardSize = (320 / 3) - 21.66;
  const cardPadCount = enableLoop ? 3 : 0;

  /**
   * Render carousel methods.
   * @param {object} props.
   * Parameters of carousel.
   */
  const renderMethodsCarousel = (props) => {
    const { cursor, carouselState, ...rest } = props;
    const dataLength = methods.length;
    let current = -Math.round(cursor) % dataLength;
    while (current < 0) {
      current += dataLength;
    }

    const translateX = (cursor - cardPadCount) * cardSize + (carouselWidth - cardSize) / 2 - (cardSize + 18 + (5 * Math.abs(cursor)));

    return (
      <MethodFormItemElement name="paymentMethod">
        <PaymentRadioGroup>
          <NonPassiveTouchTarget className='carousel-container'>
            <NonPassiveTouchTarget
              className="carousel-track"
              style={{ transform: `translate3d(${translateX}px, 0, 0)` }}
              {...rest}>
            </NonPassiveTouchTarget>
          </NonPassiveTouchTarget>
        </PaymentRadioGroup>
      </MethodFormItemElement>
    )
  };

  /**
   * HOC for basic mouse support.
   * Simulates touch events with mouse events.
   */
  const CarouselComponent = touchWithMouseHOC(renderMethodsCarousel);

  /**
   * Carousel select method handler.
   * @param {number} index.
   * Index of selected element.
   */
  const carouselSelectMethodHandler = (index) => {
    const { current } = carouselRef;
    index > 0 ? current.go(-index + 1) : current.go(0);
  };

  /**
   * Render carousel method.
   * @param {number} index.
   * Callback of index number of each carousel element.
   * @param {number} modIndex.
   * Callback of position index number of carousel element.
   */
  const renderCarouselMethod = (index, modIndex) => {
    const filteredMethods = sortMethods(methods);
    const filteredMethod = filteredMethods[modIndex];

    const { currencyAsset } = appStore.getMainAttributes;
    const { apiVersion } = appStore.mainAttributes;

    return (
      filteredMethod && (
        <MobileRadioButton
          className='carousel-card'
          cardSize={cardSize}
          onClick={() => carouselSelectMethodHandler(index)}
          key={index}
          value={filteredMethod.guid}
          disabled={appStore.getIsTransactionStarted}
        >
          <CarouselCardInner>
            <CarouselMethodImageWrapper name={filteredMethod.label}>
              {((filteredMethod.logo_url && validateUrl(filteredMethod.logo_url)) || (filteredMethod.mobile_logo_url && validateUrl(filteredMethod.mobile_logo_url))) ? (
                <img src={filteredMethod.mobile_logo_url || filteredMethod.logo_url} alt=""/>
              ) : (
                <DefaultIconWrapper>
                  {PAYMENT_METHOD_DEFAULT_ICON[filteredMethod.payment_group] || <DefaultPaymentMethodIcon />}
                </DefaultIconWrapper>
              )}
            </CarouselMethodImageWrapper>
            <CarouselMethodName>{t('payment.method.currency')}: {replaceColon(filteredMethod.delivery_currency)[1]}</CarouselMethodName>
	          <CarouselRadioInfoFeePercent>
              {filteredMethod.customer_fee_percent > 0 ? (
								<>
									{t('payment.method.selected.fee')}: {filteredMethod.customer_fee_fixed > 0 && (
									<>
										{apiVersion === 3
										? filteredMethod?.method_symbol || filteredMethod?.method_currency || ''
										: currencyAsset && (currencyAsset.symbol ? currencyAsset.symbol : ` ${currencyAsset.code}`)}
										{filteredMethod.customer_fee_fixed}
									</>
								)}
									{(!!filteredMethod.customer_fee_fixed && !!filteredMethod.customer_fee_percent) && ' + '}
									{roundTo(filteredMethod.customer_fee_percent, 2)}%
								</>
              ) : (
								<>
									{t('payment.method.selected.fee')}: { apiVersion === 3
									? filteredMethod?.method_symbol || filteredMethod?.method_currency || ''
									: currencyAsset && (currencyAsset.symbol ? currencyAsset.symbol : ` ${currencyAsset.code}`)}
									{filteredMethod.customer_fee_fixed}
								</>
							)}
            </CarouselRadioInfoFeePercent>
          </CarouselCardInner>
        </MobileRadioButton>
      )
    )
  };

  return (
    <TouchCarousel
      ref={carouselRef}
      component={CarouselComponent}
      cardCount={methods.length}
      cardSize={200}
      loop={enableLoop}
      cardPadCount={cardPadCount}
      renderCard={renderCarouselMethod}
    />
  )
};

export default inject('appStore')(observer(MethodsCarouselComponent));
