/**
 * @file
 * Contains payment method form component.
 */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  MethodFormDataWrapper,
  MainTop,
  MainMiddle,
  MainBottom,
  MainTitle,
  MainDescription,
  MainImageContainer,
  MainImageWrapper,
  MainImage,
  MainFieldsWrapper,
  MainTitleText,
  DefaultIconWrapper,
  BankCardSecureIconsWrapper,
  LanguageSwitcherContainer,
} from './PaymentMethodFormData.style';
import PaymentMethodFormWrapper from 'FastPaymentFlow/components/PaymentMethodFormWrapper/PaymentMethodFormWrapper';
import {
	expirationDateFormatField,
	renderCardNumberField,
	renderEmailField,
	renderHiddenField,
	renderInputField,
	renderPhoneField,
	renderToBankNameField,
} from '../PaymentMethodFormFields';
import PaymentMethodFormSubmit from '../PaymentMethodFormSubmit';
import { FLOW_TYPE_MAPPING } from 'common/constants/flowType.constant';
import Popover from 'common/components/PopoverElement/PopoverElement';
import FormItemElement from 'common/components/FormItemElement/FormItemElement';
import InputElement from 'common/components/InputElement/InputElement';
import PaymentStepCrypto from '../PaymentStepCrypto/PaymentStepCrypto';
import { PAYMENT_METHOD_TYPES } from 'common/constants/paymentMethods.constants';
import PAYMENT_METHODS_STEPS from 'common/constants/paymentMethod.step.constant';
import { PAYMENT_METHOD_TITLE, PAYMENT_METHOD_DESCRIPTION } from 'common/constants/paymentMethodText.constant';
import PAYMENT_METHOD_ADDITIONAL_TEXT from '../PaymentMethodAdditionalText';
import { PAYMENT_METHOD_DEFAULT_ICON } from 'common/constants/paymentMethodDefaultIcon';
import DefaultPaymentMethodIcon from 'common/assets/renderedSvgIcons/DefaulPaymentMethodIcon';
import { MOBILE } from 'common/constants/view.constants';
import PciDssIcon from 'common/assets/renderedSvgIcons/PciDssIcon';
import CardSecureIcon from 'common/assets/renderedSvgIcons/CardSecureIcon';
import SecurePaymentPageIcon from 'common/assets/renderedSvgIcons/SecurePaymentPageIcon';
import { validateUrl } from 'common/utils/urlValidation';
import { useTranslation } from 'react-i18next';
import { fieldsOrderFilter } from 'common/utils/filedsOrderFilter.util';
import LanguageSwitcher from 'common/components/LanguageSwitcher/LanguageSwitcher';

const PaymentMethodFormData = (props) => {
  const {
    appStore,
    paymentStore,
    viewHocStore,
    form,
    data: {
      flowType,
      amount: propsAmount,
      redirectUrl,
      selectedPaymentMethodData: paymentMethodData,
      callbackDataHandler,
    },
    txCallbackData,
    isLeftColumnHidden,
  } = props;

  /**
   * Use translation.
   */
  const { t } = useTranslation();

  /**
   * Exchange currency.
   */
  const [currencyData, setCurrencyData] = useState({});

  /**
   * External ID
   */
  const [externalId, setExternalId] = useState('');

  /**
   * Set default fields value.
   */
  useEffect(() => {
    const { currencies, currencyAsset } = appStore.getMainAttributes;
    setCurrencyData(currencyAsset);

    form.setFields([
      {
        name: 'redirect_url',
        value: redirectUrl,
      },
      {
        name: 'currency',
        value: currencies?.code && currencies.code,
      },
    ]);
  }, [paymentMethodData]);

  /**
   * Set external id.
   */
  useEffect(() => {
    const { externalId } = appStore.getMainAttributes;
    setExternalId(externalId);
  }, [appStore.getMainAttributes])

  /**
   * Render main title.
   * @param {string} flowType.
   * Transaction flow type.
   * @param {string} methodType.
   * Type of payment method group.
   * @param {string} label.
   * Payment method label.
   */
  const renderMainTitle = (flowType, methodType, label ) => {
    if (flowType && methodType) {
      const title = PAYMENT_METHOD_TITLE[flowType][methodType][label] || PAYMENT_METHOD_TITLE[flowType][methodType].default;
      const { hint, hintMessage } = PAYMENT_METHOD_TITLE[flowType][methodType];

      return (
        <MainTitle>
          <MainTitleText>
            {t(title)}
          </MainTitleText>
          {hint && (
            <Popover data={paymentMethodData} text={t(hintMessage)} />
          )}
        </MainTitle>
      )
    }
    return null;
  };

  /**
   * Render main title.
   * @param {string} flowType.
   * Transaction flow type.
   * @param {string} methodType.
   * Payment method type.
   * @param {string} methodName.
   * Payment method name.
   */
  const renderMainSubTitle = (flowType, methodType, methodName ) => {
    if (flowType && methodType) {
      if (paymentMethodData.fields) {
        const fields = paymentMethodData.fields;
        const neededFields = Object.keys(paymentMethodData.fields)
          .filter((field) => field !== 'amount' && field !== 'redirect_url' && field !== 'status_callback_url')
          .reduce((acc, value) => {
            acc[value] = fields[value];
            return acc;
          }, {});

        const subtitle = PAYMENT_METHOD_DESCRIPTION[flowType][methodType][methodName] || PAYMENT_METHOD_DESCRIPTION[flowType][methodType].default;

        if (Object.keys(neededFields).length > 0 || methodType === 'CRYPTO' || methodType !== 'BANKCARD') {
          return <MainDescription>{t(subtitle)}</MainDescription>
        }
        return <MainDescription>{t('fpf.payment.method.sub.title.redirect')}</MainDescription>
      }
      const subtitle = PAYMENT_METHOD_DESCRIPTION[flowType][methodType][methodName] || PAYMENT_METHOD_DESCRIPTION[flowType][methodType].default;
      if (methodType === 'BANKCARD') {
        return <MainDescription>{t('fpf.payment.method.sub.title.redirect')}</MainDescription>
      }
      return <MainDescription>{t(subtitle)}</MainDescription>
    }
    return null;
  };

  /**
   * Render additional text.
   * @param {string} flowType.
   * Transaction flow type.
   * @param {string} methodType.
   * Payment method type.
   * @param {object} fields.
   * Payment method fields.
   * @param {object} t.
   * Translation store parameters.
   */
  const renderAdditionalText = (flowType, methodType, fields, t ) => {
    if (flowType && methodType) {
      if (fields && (fields.hasOwnProperty('redirect_url') && (Object.keys(fields).length < 4 || Object.keys(fields).length < 2))) {
        if (PAYMENT_METHOD_ADDITIONAL_TEXT[flowType][methodType]) {
          return PAYMENT_METHOD_ADDITIONAL_TEXT[flowType][methodType](t);
        }
        return null;
      } else if (!fields) {
        if (PAYMENT_METHOD_ADDITIONAL_TEXT[flowType][methodType]) {
          return PAYMENT_METHOD_ADDITIONAL_TEXT[flowType][methodType](t);
        }
        return null;
      }
    }
    return null;
  };

  /**
   * Render payment method fields.
   */
  const renderPaymentMethodFields = () => {
    if (paymentMethodData.fields) {
      const filteredFields = fieldsOrderFilter(paymentMethodData.fields);
      const preFilledFields = appStore.getMainAttributes?.prefillFields || {};
      const errorFieldsList = paymentStore.getFieldsErrors;

      return Object.entries(filteredFields).map(([name, fieldData]) => {
	      const fieldsData = {
		      t,
		      name,
		      fieldData,
		      label: `field.label.${name}`,
		      preFilledFields,
		      errorFieldsList,
	      };

				return (
					{
						'email': <div key={name}>{renderEmailField(fieldsData)}</div>,
						'to_email': <div key={name}>{renderEmailField(fieldsData)}</div>,
						'from_email': <div key={name}>{renderEmailField(fieldsData)}</div>,
						'to_bank_card': <div key={name}>{renderCardNumberField(fieldsData)}</div>,
						'bank_code': <div key={name}>{renderToBankNameField(fieldsData)}</div>,
						'to_bank_card_exp': <div key={name}>{expirationDateFormatField(fieldsData)}</div>,
						'mobile': <div key={name}>{renderPhoneField(fieldsData)}</div>,
						'from_mobile': <div key={name}>{renderPhoneField(fieldsData)}</div>,
						'to_mobile': <div key={name}>{renderPhoneField(fieldsData)}</div>,
						'redirect_url': <div key={name}>{renderHiddenField(fieldsData)}</div>,
						'amount': <div key={name}>{renderHiddenField(fieldsData)}</div>,
						'status_callback_url': <div key={name}>{renderHiddenField(fieldsData)}</div>,
					}[name] || <div key={name}>{renderInputField(fieldsData)}</div>
				)
      })
    }
    return null;
  };

  /**
   * Render amount field.
   */
  const renderAmountField = () => {
    return paymentMethodData.fields ? (
      !('amount' in paymentMethodData.fields) && (
        <FormItemElement key="amount" name="amount" hidden>
          <InputElement/>
        </FormItemElement>
      )
    ) : (
      <FormItemElement key="amount" name="amount" hidden>
        <InputElement/>
      </FormItemElement>
    )
  };

  return (
    Object.keys(txCallbackData).length &&
    flowType === FLOW_TYPE_MAPPING.deposit &&
    paymentMethodData.payment_group === PAYMENT_METHOD_TYPES.crypto.key ? (
        <PaymentStepCrypto
          data={{
            t,
            paymentMethodData,
            propsAmount,
            flowType,
            currencyData,
            txCallbackData,
            callbackDataHandler,
            isLeftColumnHidden,
          }}
        />
    ) : Object.keys(txCallbackData).length &&
      flowType === FLOW_TYPE_MAPPING.deposit &&
      !validateUrl(txCallbackData.txUrl) ? (
        PAYMENT_METHODS_STEPS[txCallbackData.txUrl] ? (
          PAYMENT_METHODS_STEPS[txCallbackData.txUrl].component({
            t,
            paymentMethodData,
            propsAmount,
            flowType,
            currencyData,
            txCallbackData,
            callbackDataHandler,
            isLeftColumnHidden,
          })
        ) : (
          PAYMENT_METHODS_STEPS.default.component({
            t,
            paymentMethodData,
            propsAmount,
            flowType,
            currencyData,
            txCallbackData,
            callbackDataHandler,
            isLeftColumnHidden,
          })
        )
    ) : (
      <MethodFormDataWrapper>
        <PaymentMethodFormWrapper>
          <MainTop isLeftColumnHidden={isLeftColumnHidden}>
            {(
              (viewHocStore.getViewMode !== MOBILE)
              || (isLeftColumnHidden && viewHocStore.getViewMode === MOBILE)
              ) && (
              <MainImageContainer>
                <MainImageWrapper paymentGroup={paymentMethodData.payment_group} methodName={paymentMethodData.label}>
                  {(paymentMethodData.mobile_logo_url && validateUrl(paymentMethodData.mobile_logo_url)) || (paymentMethodData.logo_url && validateUrl(paymentMethodData.logo_url)) ? (
                    <MainImage src={paymentMethodData.mobile_logo_url || paymentMethodData.logo_url} alt="logo"/>
                  ) : (
                    <DefaultIconWrapper>
                      {PAYMENT_METHOD_DEFAULT_ICON[paymentMethodData.payment_group] || <DefaultPaymentMethodIcon />}
                    </DefaultIconWrapper>
                  )}
                </MainImageWrapper>

                <LanguageSwitcherContainer>
                  <LanguageSwitcher />
                </LanguageSwitcherContainer>
              </MainImageContainer>
            )}

            {renderMainTitle(flowType, paymentMethodData.payment_group, paymentMethodData.label)}

            {renderMainSubTitle(flowType, paymentMethodData.payment_group, paymentMethodData.label)}

            <BankCardSecureIconsWrapper isLeftColumnHidden={isLeftColumnHidden}>
              <CardSecureIcon />
              <PciDssIcon />
              <SecurePaymentPageIcon />
            </BankCardSecureIconsWrapper>

          </MainTop>
          <MainMiddle
            isLeftColumnHidden={isLeftColumnHidden}
            fieldsQuantity={!!paymentMethodData.fields && Object.keys(paymentMethodData.fields).length}
          >
            <MainFieldsWrapper>
              {renderPaymentMethodFields()}
              {renderAmountField()}
              {renderAdditionalText(flowType, paymentMethodData.payment_group, paymentMethodData.fields, t)}
              <FormItemElement key="currency" name="currency" hidden>
                <InputElement/>
              </FormItemElement>
              {paymentMethodData.fields && paymentMethodData.fields.hasOwnProperty('external_id') && externalId && (
                <FormItemElement initialValue={externalId} key="external_id" name="external_id" hidden>
                  <InputElement/>
                </FormItemElement>
              )}
            </MainFieldsWrapper>
          </MainMiddle>
          <MainBottom>
            <PaymentMethodFormSubmit
              data={{ propsAmount, flowType, paymentMethodData }}
              form={form}
            />
          </MainBottom>
        </PaymentMethodFormWrapper>
      </MethodFormDataWrapper>
    )
  )
};

export default inject('appStore', 'paymentStore', 'viewHocStore')(observer(PaymentMethodFormData));
