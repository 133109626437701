/**
 * @file
 * Contains i18n localization configurations.
 */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import GeneralConfig from 'generalConfig.config';
import Utils from 'common/utils';
import translateEN from './translations/en/translations.json';
import translatePT from './translations/pt/translations.json';
import translateDE from './translations/de/translations.json';
import translateUZ from './translations/uz/translations.json';
import translateRU from './translations/ru/translations.json';

// the translations
const resources = {
  en: {
    translation: translateEN,
  },
  pt: {
    translation: translatePT,
  },
  de: {
    translation: translateDE,
  },
  uz: {
    translation: translateUZ,
  },
  ru: {
    translation: translateRU,
  },
};

i18n
  .use(initReactI18next)
  .init({
    lng: Utils.getLanguage(),
    resources,
    fallbackLng: GeneralConfig.defaultLanguage,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translation',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
  });

export default i18n;
