/**
 * @file
 * Contains Language Switcher component.
 */

import React from 'react';
import { GlobalStyles, Select } from './LanguageSwitcher.style';
import GeneralConfig from 'generalConfig.config';
import i18n from 'i18next';
import Utils from 'common/utils';

function LanguageSwitcher() {
  /**
   * On change handler.
   * @param {string} lng - Current selected language key.
   */
  const onChangeHandler = async (lng) => {
    await i18n.changeLanguage(lng);
    try {
      localStorage.setItem('fpf-i18nextLng', lng);
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <>
      {GeneralConfig.languages.length > 1 && (
        <>
          <GlobalStyles />
          <Select
            popupClassName="popup-language-switcher"
            onChange={(value) => onChangeHandler(value)}
            defaultValue={() => GeneralConfig.languages.filter(item => item.value === Utils.getLanguage())}
            options={GeneralConfig.languages}
          />
        </>
      )}
    </>
  );
}

export default LanguageSwitcher;
