/**
 * @file
 * Contains payments container component.
 */
import React, { useEffect, useState, useLayoutEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Form from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import { FLOW_TYPE_MAPPING, FLOW_TYPE_BACK_MAPPING } from 'common/constants/flowType.constant';
import TransactionStatus from 'common/components/TransactionStatus/TransactionStatus';
import { validateUrl } from 'common/utils/urlValidation';
import { normalizeOperationStatus } from 'common/utils/operationStatus.utils';
import { WithdrawTransactionStatusWrapper } from '../../FastPaymentFlowMethods.style';
import SpinnerProcessing from 'common/components/Spinner/SpinnerProcessing';
import PaymentMethodIframe from '../PaymentMethodIframe/PaymentMethodIframe';
import queryString from 'query-string';
import { PaymentMethodForm } from '../../FastPaymentFlow.style';
import PaymentMethodFormData from '../PaymentMethodFormData/PaymentMethodFormData';
import WithdrawTransactionStatus from 'common/components/TransactionStatus/WithdrawTransactionStatus';
import { API_V3, API_V2, MAIN_API_URL } from 'common/constants/apiEnpoints.constants';
import { isUrlHasQueryParams } from 'common/utils/urlValidation';
import useQuery from 'common/hooks/useQuery.hook';
import isDevMode from 'common/utils/devModeChecker.util';

function PaymentsContainer (props) {
  const {
    paymentStore,
    appStore,
    isLeftColumnHidden,
  } = props;

  /**
   * Use translation.
   */
  const { t, i18n } = useTranslation();

  /**
   * Redirect timeout.
   */
  const REDIRECT_TIMEOUT = 12000;

  /**
   * Use query params.
   */
  const { jwt: queryParamToken } = useQuery(['jwt']);

  /**
   * Use form object.
   */
  const [form] = Form.useForm();

  /**
   * Selected payment method data.
   */
  const [selectedPaymentMethodData, setSelectedPaymentMethodData] = useState({});

  /**
   * Selected payment method data.
   */
  const [flowType, setFlowType] = useState('');

  /**
   * Iframe url.
   */
  const [iframeUrl, setIframeUrl] = useState('');

  /**
   * Amount.
   */
  const [amount, setAmount] = useState(0);

  /**
   * Redirect url.
   */
  const [redirectUrl, setRedirectUrl] = useState('');

  /**
   * Transaction status.
   */
  const [transactionStatus, setTransactionStatus] = useState('');

  /**
   * Transaction callback data.
   */
  const [txCallbackData, setTxCallbackData] = useState({});

  /**
   * Transaction  status callback url data.
   */
  const [statusCallbackUrl, setStatusCallbackUrl] = useState('');

  /**
   * Fields error list (fields which come from back-end validation).
   */
  const [fieldsError, setFieldsError] = useState([]);

  /**
   * Get operation status from redirect url.
   */
  useLayoutEffect(() => {
    const statusInfo = queryString.parse(window?.location?.search);

    if (statusInfo?.status) {
      setTransactionStatus(normalizeOperationStatus(statusInfo?.status));
    }
  }, [window.location]);

  /**
   * Get payment method data.
   */
  useEffect(() => {
    const flowType = paymentStore.getFlowType;
    const {
      amount,
      redirectUrl,
      statusCallbackUrl,
    } = appStore.getMainAttributes;
    setFlowType(flowType);

    amount && setAmount(amount);
    setRedirectUrl(redirectUrl);
    setStatusCallbackUrl(statusCallbackUrl);
  }, [appStore.getMainAttributes, paymentStore.getPaymentMethodsData, paymentStore.getFlowType]);

  /**
   * On method change.
   */
  useLayoutEffect(() => {
    // Clear errors flag.
    paymentStore.setTransactionAnswerFieldError(0);
    setFieldsError([]);
    form.resetFields();

    // Clear txCallback data.
    setTxCallbackData({});
    setTransactionStatus('');

    // Set selected payment method data.
    if (paymentStore.getSelectedPaymentMethod !== '') {
      const selectedMethodId = paymentStore.getSelectedPaymentMethod;
      const paymentsMethodsData = paymentStore.getPaymentMethodsData;
      setSelectedPaymentMethodData({
        ...paymentsMethodsData[selectedMethodId],
      });
    }
  }, [paymentStore.getSelectedPaymentMethod]);

  /**
   * [KYC]: If KYC was approved we need call submit form handler again to continue with transaction.
   */
  useEffect(() => {
    (async() => {
      if (paymentStore.getKycStatus === 'approved') {
        const fieldsValues = form.getFieldsValue();
        await onSubmitFormHandler(fieldsValues);
        paymentStore.setKycStatusAction('');
      }
    })();
  }, [paymentStore.getKycStatus]);

  /**
   * Form submit handler.
   * @param {object} values - Form fields values.
   */
  const onSubmitFormHandler = async (values) => {
	  await form.validateFields();
    if (fieldsError.length) {
      const errorFieldsList = [];
      fieldsError.forEach(fieldName => {
        errorFieldsList.push({
          name: fieldName,
          errors: [t('error.invalid')],
        });
      });
      form.setFields(errorFieldsList);
    } else {
      appStore.setIsAppLoading('processing');
      const {
        token,
        apiVersion,
        transactionId,
        merchantGuid,
        isKYC,
        receiverId,
        senderId,
        successRedirectUrl,
        failRedirectUrl,
      } = appStore.getMainAttributes;

      try {
        const {
          amount,
          currency,
          billing_country_code,
          minAmount,
          maxAmount,
          customer_amount,
          processingFee,
          deliveryCurrency,
          deliveryAmount,
          to_bank_card,
          ...rest
        } = values;
        appStore.setTxStatusData(values);
        const neededFields = !!selectedPaymentMethodData.fields && Object.keys(selectedPaymentMethodData?.fields);
        const checkedNeededFields = neededFields
          ? !!selectedPaymentMethodData.fields && neededFields.every((fieldName) => fieldName in values)
          : true;
        const selectedPaymentMethodId = paymentStore.getSelectedPaymentMethod;

        if (checkedNeededFields) {
          const isDeposit = flowType === FLOW_TYPE_MAPPING.deposit;
          const isWithdraw = flowType === FLOW_TYPE_MAPPING.withdraw;

          // Deposit data.
          const deposit = {
            ...rest,
            ...(!!to_bank_card && { to_bank_card: to_bank_card?.replace(/\s/g, "") }),
            ...(appStore.getMainAttributes?.redirectUrl && {redirect_url: appStore.getMainAttributes.redirectUrl}),
            ...(statusCallbackUrl && {status_callback_url: statusCallbackUrl}),
	          ...(appStore.getMainAttributes?.externalId && {external_id: appStore?.getMainAttributes?.externalId}),
          };

          // Remit data.
          const remit = {
            ...rest,
            ...(!!to_bank_card && { to_bank_card: to_bank_card?.replace(/\s/g, "") }),
            ...(billing_country_code && {billing_country: billing_country_code}),
	          ...(appStore.getMainAttributes?.redirectUrl && {redirect_url: appStore.getMainAttributes.redirectUrl}),
            ...(statusCallbackUrl && {status_callback_url: statusCallbackUrl}),
	          ...(appStore.getMainAttributes?.externalId && {external_id: appStore?.getMainAttributes?.externalId}),
          };

          // Main data for submit.
          const fieldsData = {
            ...(isDeposit && {
              // Delete for dev mode, so that it would be possible to create a transaction several times
              ...(!isDevMode && { deposit_transaction_id: transactionId }),
              deposit_method: selectedPaymentMethodId,
              deposit,
            }),
            ...(isWithdraw && {
              // Delete for dev mode, so that it would be possible to create a transaction several times
              ...(!isDevMode && {  remit_transaction_id: transactionId }),
              remit_method: selectedPaymentMethodId,
              remit,
            })
          };

          const data = {
            ...(isKYC && {
              sender_id: senderId,
              receiver_id: receiverId,
            }),
            amount: Number(amount),
            mode: isDeposit ? 'deposit' : 'remit',
            fields: {
              transaction: { ...fieldsData },
            },
            lang: i18n.language,
          };

          const v3TransactionEndpoint = `${MAIN_API_URL}${API_V3}/${merchantGuid}`;
          const v2TransactionEndpoint = `${MAIN_API_URL}${API_V2}/${merchantGuid}`;
          const transactionRequestUrl = (apiVersion === 2) ? v2TransactionEndpoint : v3TransactionEndpoint;
          const requestToken = (apiVersion === 2) ? queryParamToken : token;
          const transactionResponse = await paymentStore.makeTransaction(data, requestToken, transactionRequestUrl);

          if (transactionResponse?.message === 'customer_info_needed') {
            // [KYC]: if '/transaction' request answered as 'customer_info_needed' we need to run KYC client,
            // so we need additional information for this from '/customer' request.
            const individualIdMapping = {
              'individual_sender': senderId,
              'individual_receiver': receiverId,
            }
            const individualId = individualIdMapping[transactionResponse.senderType];
            const customerResponse = await paymentStore.getCustomerAction(
              transactionRequestUrl,
              requestToken,
              individualId,
              transactionResponse.senderType
            );
            const {
              kyc_provider_config: {
                provider_type,
                data: kycData,
              }
            } = customerResponse;
            paymentStore.setKycDataAction({
              kycType: provider_type,
              kycData: kycData,
            });
          } else {
            setTxCallbackData({
              amountIn: transactionResponse?.amount_in || transactionResponse?.amount,
              txUrl: transactionResponse?.how,
              isRedirect: transactionResponse?.extra_info?.is_redirect,
              extraInfo: transactionResponse?.extra_info,
            });

            if (Object.keys(transactionResponse).length) {
              paymentStore.setTxCallBackData(transactionResponse);
            }

            if (isWithdraw) {
              window.open(
                `${successRedirectUrl}${isUrlHasQueryParams(successRedirectUrl) ? '&' : '?'}transaction_guid=${transactionResponse?.id}&trace_id=${transactionResponse?.trace_id}`,
                '_parent'
              );
              setTransactionStatus('success-delay');
            }

            appStore.setIsTransactionStarted(true);

            if (transactionResponse?.extra_info?.is_redirect) {
              const howUrl = `${transactionResponse?.how}${isUrlHasQueryParams(transactionResponse?.how) ? '&' : '?'}lang=${i18n.language}`;
              // Disable for Safari browser.
              if (window.navigator.vendor !== 'Apple Computer, Inc.') {
                window.open(howUrl, '_parent');
              }

              setTransactionStatus(howUrl);
            } else {
              window.onmessage = function(e) {
                  const cbStatus = e?.data?.cbStatus;
                  if(!!cbStatus) {
                    (cbStatus === 'success')
                      ? window.open(`${successRedirectUrl}${isUrlHasQueryParams(successRedirectUrl) ? '&' : '?'}transaction_guid=${transactionResponse?.id}`,'_parent')
                      : window.open(failRedirectUrl,'_parent');
                  }
              };

              setIframeUrl(transactionResponse?.how);
            }

            // Status page should be 'closed' if fpf doesn't have 'redirectUrl' redirect.
            setTimeout(() => {
              if (
                !transactionResponse?.extra_info?.is_redirect
                && flowType === FLOW_TYPE_MAPPING.withdraw
                && window.navigator.vendor !== 'Apple Computer, Inc.'
              ) {
                window.open(`${redirectUrl}${isUrlHasQueryParams(redirectUrl) ? '&' : '?'}status=success`, '_parent');
              }
            }, REDIRECT_TIMEOUT);
            paymentStore.setFieldsErrors([]);
            form.resetFields();
          }
        } else {
          console.error('Field set is incorrect!');
        }
      } catch (e) {
        // const error = String(e)
        //   .replace('Error: ', '')
        //   .replace('Error+Data--', '');
        const { transactionId } = appStore.getMainAttributes;
        const errorDataFlag = 'Error+Data--';

        // Set field error.
        if (String(e).startsWith(errorDataFlag)) {
          const data = JSON.parse(String(e).replace(errorDataFlag, ''));
          const type = FLOW_TYPE_BACK_MAPPING[flowType];

          if (data?.error === 'transaction_info_needed') {
            const fields = [];
            const fieldsErrorList = [...fieldsError];

            paymentStore.setTransactionAnswerFieldError(paymentStore.getTransactionAnswerFieldError + 1);

            Object.entries(data?.fields).forEach(([key, entry]) => {
              if (typeof key === 'string') {
                const fieldName = key.replace(`${type}.`, '');

                fieldsErrorList.push(fieldName);
                fields.push({
                  name: fieldName,
                  errors: [(entry?.error_code && i18n.exists(entry?.error_code)) ? t(entry?.error_code) : entry?.description],
                });
              }
            });
            paymentStore.setFieldsErrors(fieldsErrorList);
            form.setFields(fields);
          }
        } else {
          console.error(e);
          form.resetFields();
          const { data } = e.response;
          window.open(`${failRedirectUrl}${isUrlHasQueryParams(failRedirectUrl) ? '&' : '?'}trace_id=${data?.trace_id}&transaction_guid=${transactionId}`, '_parent');
        }
      } finally {
        // Status page should be 'closed'.
        setTimeout(() => {
          (window.navigator.vendor !== 'Apple Computer, Inc.') && setTransactionStatus('');
        }, REDIRECT_TIMEOUT);

        setTimeout(() => appStore.setIsAppLoading(false), 2000);
      }
    }
  };

  /**
   * On form values change handler.
   */
  const onFormValuesChangeHandler = async (value, allValues) => {
    const field = Object.keys(value)[0];
    const fieldError = form.getFieldError(field);
    if (fieldError?.length) {
      form.setFields([
        {
          name: field,
          errors: []
        }
      ]);

      const index = fieldsError.indexOf(field);

      if (index > -1) {
        const arr = fieldsError.filter(function(item) {
          return item !== field;
        });
        setFieldsError(arr);
      }

      paymentStore.setTransactionAnswerFieldError(paymentStore.getTransactionAnswerFieldError + 1);
    }

    paymentStore.setFormFieldsData(allValues);
  };

  /**
   * On submit failed handler.
   */
  const onSubmitFailedHandler = ({ errorFields }) => {
    if(errorFields?.length) {
      const errorFieldsMap = errorFields?.map((t) => (t?.name?.[0]));
      paymentStore.setFieldsErrors(errorFieldsMap);
    }
    if (fieldsError.length) {
      const errorFieldsList = [];
      fieldsError.forEach(fieldName => {
        errorFieldsList.push({
          name: fieldName,
          errors: [t('error.invalid')],
        });
      });
      form.setFields(errorFieldsList);

      return null;
    }
  };

  return (
    <>
      {transactionStatus ? (
        flowType === FLOW_TYPE_MAPPING.deposit ? (
          (
            (txCallbackData.txUrl === 'SEPA') ||
            (flowType === FLOW_TYPE_MAPPING.deposit && selectedPaymentMethodData.payment_group === 'CRYPTO')
          ) ? (
            <SpinnerProcessing/>
          ) : (
            <TransactionStatus
              url={transactionStatus}
              closeCallback={() => setTransactionStatus('')}
              selectedPaymentMethodData={selectedPaymentMethodData}
            />
          )
        ) : (
          <WithdrawTransactionStatusWrapper>
            <WithdrawTransactionStatus type={transactionStatus} />
          </WithdrawTransactionStatusWrapper>
        )
      ) : (
        validateUrl(txCallbackData.txUrl) && !txCallbackData.isRedirect ? (
          <PaymentMethodIframe data={{ selectedPaymentMethodData, callbackDataHandler: setTxCallbackData, }} txCallbackData={txCallbackData} source={iframeUrl} />
        ) : (
          <PaymentMethodForm
            form={form}
            onFinish={onSubmitFormHandler}
            onFinishFailed={onSubmitFailedHandler}
            scrollToFirstError={true}
            layout="vertical"
            onValuesChange={(value, allValues) => onFormValuesChangeHandler(value, allValues)}
          >
            <PaymentMethodFormData
              form={form}
              data={{
                flowType,
                amount,
                redirectUrl,
                selectedPaymentMethodData,
                callbackDataHandler: setTxCallbackData,
                statusCallbackUrl,
              }}
              txCallbackData={txCallbackData}
              isLeftColumnHidden={isLeftColumnHidden}
            />
          </PaymentMethodForm>
        )
      )}
    </>
  );
}

export default inject('paymentStore', 'appStore')(observer(PaymentsContainer));
