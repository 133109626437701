/**
 * @file
 * Contains fast payments flow main view.
 */

import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import Form from 'antd/lib/form';
import {
  PaymentRadioGroup,
  PaymentMethodsNavigatorWrapper,
  RadioInfoContentWrapper,
  RadioInfoImageWrapper,
  RadioInfoImageContainer,
  RadioInfoWrapper,
  RadioInfoFeePercent,
  MethodRadioButton,
  PaymentNavigationForm,
  MethodFormItemElement,
  DefaultIconWrapper,
  PaymentMethodsPartnersLogo,
  LeftSideTopContainerStyle,
  LeftSideLanguageSwitcherContainer,
} from '../../FastPaymentFlow.style';
import { PAYMENT_METHOD_TYPES } from 'common/constants/paymentMethods.constants';
import { DEFAULT_VALUES_MAPPING } from 'common/constants/paymentDefaultValue.constants';
import useViewMode from 'common/hooks/useViewMode.hook';
import { DESKTOP } from 'common/constants/view.constants';
import { roundTo } from 'common/utils/roundNumber.util';
import { PAYMENT_METHOD_DEFAULT_ICON } from 'common/constants/paymentMethodDefaultIcon';
import DefaultPaymentMethodIcon from 'common/assets/renderedSvgIcons/DefaulPaymentMethodIcon';
import LanguageSwitcher from 'common/components/LanguageSwitcher/LanguageSwitcher';
import GeneralConfig from 'generalConfig.config';
import MethodsCarouselComponent from 'common/components/MethodsCarouselComponent/MethodsCarouselComponent';
import { validateUrl } from 'common/utils/urlValidation';
// import TabsElement from 'common/components/Tabs/Tabs';
// import TabsPaneElement from 'common/components/Tabs/TabPaneElement';
import { useTranslation } from 'react-i18next';
import { replaceColon } from 'common/utils/text.util';

const PaymentMethodsNavigator = (props) => {
  const {
    paymentStore,
    appStore,
    viewHocStore,
  } = props;

	const { t } = useTranslation();

  /**
   * Display mode hook.
   */
  const [mode] = useViewMode();

  /**
   * Set up window width and display mode on load.
   */
  useEffect(() => {
    viewHocStore.setViewMode(mode);
  });

  /**
   * Use form object;
   */
  const [form] = Form.useForm();

  /**
   * Payment methods state.
   */
  const [paymentMethods, setPaymentMethods] = useState({});

  /**
   * Payment method value.
   */
  const [methods, setMethods] = useState([]);

  /**
   * Partner logo.
   */
  const [partnerLogo, setPartnerLogo] = useState('');

  /**
   * Get payments methods.
   */
  useEffect(() => {
    const methods = paymentStore.getPaymentMethodsData;
    setPaymentMethods(methods);
    const allMethodsByType = getPaymentMethodsByType(methods, PAYMENT_METHOD_TYPES.all.key);
    setMethods(allMethodsByType);
    const filtered = sortMethods(allMethodsByType);
    if (allMethodsByType.length) {
      const defaultSelectedValue = DEFAULT_VALUES_MAPPING[PAYMENT_METHOD_TYPES.all.key].defaultValue;

      if (paymentStore.getCurrentMethod) {
        form.setFieldsValue({ paymentMethod: paymentStore.getCurrentMethod });
        paymentStore.setSelectedPaymentMethod(paymentStore.getCurrentMethod);
      } else {
        const selectedMethod = getDefaultValue(filtered, defaultSelectedValue);

        if (filtered[0]) {
          const defaultValue = selectedMethod ? selectedMethod.guid : filtered[0].guid;

          form.setFieldsValue({ paymentMethod: defaultValue });
          paymentStore.setSelectedPaymentMethod(defaultValue);
        } else {
          paymentStore.setNoAvailableMethods(true);
        }
      }
    } else {
      paymentStore.setNoAvailableMethods(true);
    }

  }, [
    appStore.getMainAttributes,
    paymentStore.getPaymentMethodsData,
    paymentStore.getCurrentMethod,
    paymentStore.getFlowType,
  ]);

  /**
   * Set partner logo.
   */
  useEffect(() => {
    setPartnerLogo(appStore.getPartnerLogo);
  }, [appStore.partnerLogo]);

  /**
   * Render tab label.
   * @param {object} value.
   * Payment method data.
   */
  // const renderTabLabel = (value) => {
  //   if ('icon' in value) {
  //     const { icon: Icon } = value;
  //     return (
  //       <span>
  //         <Icon/> {t(value.label)}
  //       </span>
  //     );
  //   }
  //
  //   return (
  //     <>{t(value.label)}</>
  //   );
  // };

  /**
   * Sort methods by type.
   * @param {array} methods.
   * Array of methods.
   */
  const sortMethods = (methods) => {
    const wires = methods.filter((method) => {
      return method.payment_group === PAYMENT_METHOD_TYPES.wiretransfer.key;
    });

    const ewallet = methods.filter((method) => {
      return method.payment_group === PAYMENT_METHOD_TYPES.ewallet.key;
    });

    const bankCard = methods.filter((method) => {
      return method.payment_group === PAYMENT_METHOD_TYPES.bankcard.key;
    });

    const crypto = methods.filter((method) => {
      return method.payment_group === PAYMENT_METHOD_TYPES.crypto.key;
    });

    const qrCode = methods.filter((method) => {
      return method.payment_group === PAYMENT_METHOD_TYPES.qrcode.key;
    });

    const cash = methods.filter((method) => {
      return method.payment_group === PAYMENT_METHOD_TYPES.cash.key;
    });

    return [...bankCard, ...wires, ...ewallet, ...crypto, ...qrCode, ...cash];
  };

  /**
   * Get default value.
   * @param {array} methods.
   * Payment methods.
   * @param {string} selectedGuid.
   * Selected payment method guid.
   */
  const getDefaultValue = (methods, selectedGuid) => {
    const selectedMethod = methods.filter((method) => method.guid === selectedGuid);
    return selectedMethod[0];
  };

  /**
   * Render tabs content.
   * @param {string} tabKey.
   * Selected method type.
   */
  const renderTabsContent = (tabKey) => {
    const methods = getPaymentMethodsByType(paymentMethods, tabKey);
    const filtered = sortMethods(methods);
    const { currencyAsset } = appStore.getMainAttributes;

    return (
      <>
        {tabKey === PAYMENT_METHOD_TYPES.all.key ? (
          <MethodFormItemElement name="paymentMethod">
            <PaymentRadioGroup>
              {filtered.map((method) => {
                const {
                  method_currency,
                  method_symbol,
                } = method;
                const paymentMethodCurrency =
                  method_symbol
                    ? method_symbol
                    : method_currency
                      ? `${method_currency} `
                      : null;
                const v2Currency = currencyAsset?.symbol
                  ? currencyAsset?.symbol
                  : currencyAsset?.code
                    ? `${currencyAsset?.code} `
                    : null;

                const currency = paymentMethodCurrency || v2Currency;

                return (
                  <MethodRadioButton key={method.guid} value={method.guid} disabled={appStore.getIsTransactionStarted}>
                    <RadioInfoContentWrapper>
                      <RadioInfoImageContainer>
                        <RadioInfoImageWrapper methodName={method.label}>
                          {(method.logo_url && validateUrl(method.logo_url)) ? (
                            <img src={method.logo_url} alt=""/>
                          ) : (
                            PAYMENT_METHOD_DEFAULT_ICON[method.payment_group] || <DefaultPaymentMethodIcon />
                          )}
                        </RadioInfoImageWrapper>
                      </RadioInfoImageContainer>
                      <RadioInfoWrapper>
                        <h3>{method.label || method.payment_group_name}</h3>
                        <p>{t('payment.method.currency')}: {replaceColon(method.delivery_currency)[1]}</p>
                        <RadioInfoFeePercent>
	                        {method.customer_fee_percent > 0 ? (
														<>
															{t('payment.method.selected.fee')}: {method.customer_fee_fixed > 0 && `${currency}${method.customer_fee_fixed}`}
															{(!!method.customer_fee_fixed && !!method.customer_fee_percent) && ' + '}
															{roundTo(method.customer_fee_percent, 2)}%
														</>
                          ) : (
														<>
															{t('payment.method.selected.fee')}: {currency}{method.customer_fee_fixed}
		                        </>
	                        )}
                        </RadioInfoFeePercent>
                      </RadioInfoWrapper>
                    </RadioInfoContentWrapper>
                  </MethodRadioButton>
                )
              })}
            </PaymentRadioGroup>
          </MethodFormItemElement>
        ) : (
          <MethodFormItemElement name="paymentMethod">
            <PaymentRadioGroup>
              {methods.map((method) => (
                <MethodRadioButton key={method.guid} value={method.guid}>
                  <RadioInfoContentWrapper>
                    <RadioInfoImageContainer>
                      <RadioInfoImageWrapper type={method.payment_group} name={method.payment_group}>
                        {(method.logo_url && validateUrl(method.logo_url)) ? (
                          <img src={method.logo_url} alt=""/>
                        ) : (
                          <DefaultIconWrapper>
                            {PAYMENT_METHOD_DEFAULT_ICON[method.payment_group] || <DefaultPaymentMethodIcon />}
                          </DefaultIconWrapper>
                        )}
                      </RadioInfoImageWrapper>
                    </RadioInfoImageContainer>
                    <RadioInfoWrapper>
                      <h3>{method.label || method.payment_group_name}</h3>
                      <p>{t('payment.method.currency')}: {replaceColon(method.delivery_currency)[1]}</p>
                      <RadioInfoFeePercent>
                        {method.customer_fee_percent > 0 ? (
													<>
														{t('payment.method.selected.fee')}: {method.customer_fee_fixed > 0 && (
															<>
																{currencyAsset && (currencyAsset.symbol ? currencyAsset.symbol : ` ${currencyAsset.code}`)}{method.customer_fee_fixed}
															</>
													  )}
														{(!!method.customer_fee_fixed && !!method.customer_fee_percent) && ' + '}
														{roundTo(method.customer_fee_percent, 2)}%
													</>
                        ) : (
													 <>
														 {t('payment.method.selected.fee')}: {currencyAsset && (currencyAsset.symbol ? currencyAsset.symbol : ` ${currencyAsset.code}`)}{method.customer_fee_fixed}
													 </>
	                      )}
											</RadioInfoFeePercent>
                    </RadioInfoWrapper>
                  </RadioInfoContentWrapper>
                </MethodRadioButton>
              ))}
            </PaymentRadioGroup>
          </MethodFormItemElement>
        )}
      </>
    )
  };

  /**
   * Render tabs.
   */
  const renderTabs = () => {
    // const countMethods = Object.keys(paymentMethods).length;

    // const tabsData = Object.values(PAYMENT_METHOD_TYPES).filter((paymentMethodTypes) => {
    //   return Object.values(paymentMethods).some((paymentMethod) => {
    //     // If tab type is 'POPULAR'.
    //     if (countMethods > 1 && PAYMENT_METHOD_TYPES?.popular) {
    //       if (paymentMethod?.iIsPopular && paymentMethodTypes.key === PAYMENT_METHOD_TYPES.popular.key) return true;
    //     }
    //     // If tab type is 'ALL'.
    //     if (countMethods > 1 && paymentMethodTypes.key === PAYMENT_METHOD_TYPES.all.key) return true;
    //     return paymentMethod.payment_group === paymentMethodTypes.key;
    //   })
    // });

    // return tabsData.map((data) => (
    //   <TabsPaneElement
    //     tab={renderTabLabel(data)}
    //     key={data.key}
    //     name={data.key}
    //   >
    //     {mode === DESKTOP ? (
    //       <>
    //         {renderTabsContent(data.key)}
    //       </>
    //     ) : (
    //       <>
    //         {renderMethodsCarouselComponent()}
    //       </>
    //     )}
    //   </TabsPaneElement>
    // ));

    return (
      mode === DESKTOP ? (
        <>
          {renderTabsContent(PAYMENT_METHOD_TYPES.all.key)}
        </>
      ) : (
        <>
          <MethodsCarouselComponent methods={methods} sortMethods={sortMethods}/>
        </>
      )
    );
  };

  /**
   * Change payment type handler.
   * @param {string} methodType.
   * Selected method type.
   */
  // const changePaymentTypeHandler = (methodType) => {
  //   const methods = getPaymentMethodsByType(paymentMethods, methodType);
  //   const filtered = sortMethods(methods);
  //   setMethods(methods);
  //   const defaultSelectedValue = DEFAULT_VALUES_MAPPING[methodType].defaultValue;
  //
  //   const methodsByType = methodType === PAYMENT_METHOD_TYPES.all.key ? filtered : methods;
  //
  //   const defaultSelectedMethod = getDefaultValue(methodsByType, defaultSelectedValue);
  //
  //   const defaultValue = defaultSelectedMethod ? defaultSelectedMethod.guid : methodsByType[0].guid;
  //   form.setFieldsValue({ paymentMethod: defaultValue });
  //   paymentStore.setSelectedPaymentMethod(defaultValue);
  // };

  /**
   * Changed payment method handler.
   * @param {string} paymentMethod.
   * Payment method id.
   */
  const changedPaymentMethodHandler = ({ paymentMethod }) => {
    paymentStore.setSelectedPaymentMethod(paymentMethod);
    paymentStore.setCurrentMethod(paymentMethod);
  };

  /**
   * Get payment methods by selected type.
   * @param {object} paymentMethods.
   * Payment methods data.
   * @param {string} methodType.
   * Type of payment method.
   */
  const getPaymentMethodsByType = (paymentMethods, methodType) => {
    return Object.values(paymentMethods).filter((data) => {
      if (data && Object.keys(data).length) {
        // If tab type is 'POPULAR'.
        if (PAYMENT_METHOD_TYPES?.popular) {
          if (methodType === PAYMENT_METHOD_TYPES?.popular.key && data?.iIsPopular) return true;
        }
        // If tab type is 'ALL'.
        if (methodType === PAYMENT_METHOD_TYPES.all.key) return true;
        return data.payment_group === methodType;
      }
      return false;
    });
  };

  return (
    <PaymentMethodsNavigatorWrapper>
      <LeftSideTopContainerStyle isCentered={!(GeneralConfig.languages.length > 1)}>
        {!!partnerLogo && (
          <PaymentMethodsPartnersLogo isCentered={!(GeneralConfig.languages.length > 1)}>
            <img src={partnerLogo} alt=""/>
          </PaymentMethodsPartnersLogo>
        )}
        {GeneralConfig.languages.length > 1 && (
          <LeftSideLanguageSwitcherContainer>
            <LanguageSwitcher />
          </LeftSideLanguageSwitcherContainer>
        )}
      </LeftSideTopContainerStyle>
      <PaymentNavigationForm
        form={form}
        onValuesChange={(changedValues) => changedPaymentMethodHandler(changedValues)}
        partnerlogo={`${!!partnerLogo}`}
      >
        {/*TODO: Uncomment if filter of payment method by type will be need */}
        {/*<TabsElement*/}
        {/*  {...(Object.keys(paymentMethods).length <= 1 ? {renderTabBar: () => (<></>)} : {})}*/}
        {/*  onChange={changePaymentTypeHandler}*/}
        {/*>*/}
        {/*  {renderTabs()}*/}
        {/*</TabsElement>*/}

        {renderTabs()}
      </PaymentNavigationForm>
    </PaymentMethodsNavigatorWrapper>
  );
}

export default inject('paymentStore', 'viewHocStore', 'appStore')(observer(PaymentMethodsNavigator));
