/**
 * @file
 * Contains Payment component.
 */

import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { parseDomain, fromUrl } from 'parse-domain';
import { inject, observer } from 'mobx-react';
import FastPaymentFlow from 'FastPaymentFlow/FastPaymentFlow.view';
import toml from 'toml';
import jwt_decode from 'jwt-decode';
import useQuery from 'common/hooks/useQuery.hook';
import Smartlook from 'smartlook-client';

const PaymentComponent = (props) => {
  const { appStore, paymentStore } = props;

  /**
   * Use query params.
   */
  const { token } = useQuery(['token']);

  /**
   * Main data.
   */
  const [mainData, setMainData] = useState({});

  /**
   * Get main data.
   */
  useEffect(() => {
    (async () => {
      const routeApiVersion = appStore.getRouteApiVersion;
      appStore.setIsAppLoading(true);

      try {
        if (routeApiVersion > 0) {
          if (routeApiVersion === 2) {
            const { jwt, session } = queryString.parse(window.location.search);
            const parseHostname = parseDomain(fromUrl(window.location.hostname));
            const customerIdPrefixMapping = [
              'dev-fpf-',
              'dev-2-fpf-',
              'stage-fpf-',
              'stage-2-fpf-',
              'pre-fpf-',
              'pre-prod-fpf-',
              'fpf-',
            ];
            const subdomains = parseHostname.labels.slice(0, 2);
            let customerId = '';
            subdomains.forEach((subdomain) => {
              customerIdPrefixMapping.some((prefix) => {
                if (subdomain.includes(prefix)) {
                  customerId = subdomain.replace(prefix, '');

                  return true;
                }

                return false;
              })
            });

            const {
              session_url,
              brand: {
                white_theme: {
                  assets: {
                    logo_url
                  } = {},
                  colors: {
                    main_brand_color,
                    background_color,
                    primary_text_color,
                    secondary_text_color,
                  } = {},
                } = {},
              } = {},
              amount,
              redirect_url,
              success_redirect_url,
	            failed_redirect_url,
              mode,
              deposit_callback_url,
              withdraw_callback_url,
              fee_mod,
              stellar_toml_url,
              rates_url,
              kyc_disabled = true,
              assets,
              asset_code,
              code,
              symbol,
              status_callback_url,
              api_version,
              countries,
              payment_methods,
              external_id,
              merchant_guid,
              transaction_guid, // Transaction ID
            } = await appStore.getSettingsRequest(customerId, jwt);
            const data = await appStore.getSessionRequest(session_url, session);
            const assetIssuerToml = await appStore.getAssetIssuer(stellar_toml_url);
            const tomlData = toml.parse(assetIssuerToml);
            const assetsParameters = {
              asset_code,
              symbol,
              code,
            };

            const kyc_server = tomlData?.KYC_SERVER;
            const direct_payment_server = tomlData?.DIRECT_PAYMENT_SERVER;
            const reqGetPaymentMethodsData = {
              type: mode,
              url: direct_payment_server,
              currencies: tomlData?.CURRENCIES,
              currencyAssets: assetsParameters,
              apiVersion: api_version,
              countries,
              methodIds: payment_methods,
            };
            await paymentStore.getPaymentMethods(reqGetPaymentMethodsData);

            const assetIssuer = paymentStore.getMethodsCurrency.issuer;
            const currencies = paymentStore.getMethodsCurrency;

            const infoCurrencyData = {
              currency_code: currencies.code,
              code: currencies.name,
            };

            const currencyAsset = Object.keys(assetsParameters).length && assetsParameters.asset_code ? assetsParameters : infoCurrencyData;
            // const urlSearchParams = new URLSearchParams(window.location.search);
            // const params = Object?.fromEntries(urlSearchParams.entries());

            const rockWestGuid = process.env.REACT_APP_ROCK_WEST_SMARTLOOK_MERCHANT
              ? process.env.REACT_APP_ROCK_WEST_SMARTLOOK_MERCHANT
              : '';
            if(rockWestGuid === merchant_guid) {
              Smartlook.init('7998874fa5c3a687f4e860e95e2874d9ec6b3577');
              Smartlook.identify(merchant_guid, {
                mode,
                external_id,
                aps_form: 'FPF-FORM',
              });
              Smartlook.record({
                forms: true,
                numbers: true,
                emails: true,
                ips: true
              });
            }

            appStore.setMainAttributes({
              type: mode,
              customerId: data?.psp_customer_id,
              amount,
              token: data.token,
              txCallbackUrl: mode === 'deposit' ? deposit_callback_url : withdraw_callback_url,
              merchantToken: data?.transaction_auth_token,
              redirectUrl: redirect_url,
              successRedirectUrl: success_redirect_url,
              failRedirectUrl: failed_redirect_url,
              isKyc: !kyc_disabled,
              assetIssuer,
              sessionUrl: session_url,
              session,
              apiEndpoints: {
                kyc_server,
                direct_payment_server,
                stellar_toml_url,
                rates_url,
              },
              currencies,
              currencyAsset,
              statusCallbackUrl: status_callback_url,
              apiVersion: api_version,
              countries,
              methodIds: payment_methods,
              appId: customerId,
              externalId: external_id || '',
              merchantGuid: merchant_guid,
              transactionId: transaction_guid,
              mainBrandColor: main_brand_color,
              backgroundColor: background_color,
              textBlackColor: primary_text_color,
              textGreyColor: secondary_text_color,
            });

            setMainData({
              ...data,
              appId: customerId,
              mainBrandColor: main_brand_color,
              amount,
              redirectUrl: redirect_url,
              successRedirectUrl: success_redirect_url,
              failRedirectUrl: failed_redirect_url,
              type: mode,
              txCallbackUrl: mode === 'deposit' ? deposit_callback_url : withdraw_callback_url,
              feeMod: fee_mod,
              isKycDisabled: !kyc_disabled,
              apiEndpoints: {
                stellar_toml_url,
                rates_url,
              },
              assets,
              assetsParameters,
              status_callback_url,
              apiVersion: api_version,
              tomlData,
              countries,
              logo_url,
              primary_text_color,
              background_color,
              secondary_text_color,
              payment_methods,
              deposit_callback_url,
              withdraw_callback_url,
              asset_code,
              external_id,
              token: jwt,
              session_url,
              merchant_guid,
              transaction_guid,
            });

          } else if (routeApiVersion === 3) {
            const { settings, methods } = await appStore.getAllDataRequest(token);

            // Payment methods
            const paymentMethods = methods.reduce((obj, item) => {
              if (item[settings.mode]) {
                return Object.assign(obj, {[item.guid]: item[settings.mode]});
              }

              return obj;
            }, {});
            paymentStore.setPaymentMethods(paymentMethods);

            // Set mode type
            paymentStore.setFlowType(settings.mode);

            // Currency assets params
            const assetsParameters = {
              symbol: settings.symbol,
              code: settings.code,
            };

            const tokenData = jwt_decode(token);
            const merchantGuid = tokenData.payload?.merchant_guid;
            const transactionId = tokenData.payload?.transaction_guid;
            const rockWestGuid = process.env.REACT_APP_ROCK_WEST_SMARTLOOK_MERCHANT
              ? process.env.REACT_APP_ROCK_WEST_SMARTLOOK_MERCHANT
              : '';
            if(rockWestGuid === merchantGuid) {
              const merchant_external_id = settings?.merchant_external_id;
              const mode = settings?.mode;
              const sender_id = settings?.sender_id;
              const receiver_id = settings?.receiver_id;
              Smartlook.init('7998874fa5c3a687f4e860e95e2874d9ec6b3577');
              Smartlook.identify(merchantGuid, {
                mode,
                sender_id,
                receiver_id,
                merchant_external_id,
                aps_form: 'FPF-FORM',
              });
              Smartlook.record({
                forms: true,
                numbers: true,
                emails: true,
                ips: true
              });
            }

            const { brand: {
              white_theme: {
                assets: {
                  logo_url,
                } = {},
                colors: {
                  main_brand_color,
                  background_color,
                  primary_text_color,
                  secondary_text_color,
                } = {},
              } = {},
            } = {} } = settings;

            // Set main attributes
            appStore.setMainAttributes({
              type: settings?.mode,
              customerId: merchantGuid,
              appId: merchantGuid,
              amount: settings?.amount,
              currencyAsset: assetsParameters,
              txCallbackUrl: settings?.callback_url,
              statusCallbackUrl: settings.status_callback_url,
              apiVersion: 3,
              countries: settings?.countries,
              methodIds: settings?.payment_methods,
              logo_url: logo_url,
              mainBrandColor: main_brand_color,
              backgroundColor:background_color,
              textBlackColor: primary_text_color,
              textGreyColor: secondary_text_color,
              redirectUrl: settings?.redirect_url,
              successRedirectUrl: settings?.success_redirect_url,
              failRedirectUrl: settings?.failed_redirect_url,
              prefillFields: settings?.fields || {},
              apiEndpoints: {
                rates_url: settings?.rates_url,
                stellar_toml_url: settings?.stellar_toml_url,
              },
              token,
              merchantGuid,
              transactionId, // Transaction ID.
              isKYC: !settings?.kyc_disabled,
              receiverId: settings?.receiver_id,
              senderId: settings?.sender_id,
	            externalId: settings?.merchant_external_id,
            });

            // Set main data
            setMainData({
              logo_url,
              mainBrandColor: main_brand_color,
              bg_color: background_color,
              text_black_color: primary_text_color,
              text_grey_color: secondary_text_color,
              text_main_color: settings?.text_main_color,
              main_color: settings?.main_color,
              amount: settings?.amount,
              redirectUrl: settings?.redirect_url,
              type: settings?.mode,
              txCallbackUrl: settings?.callback_url,
              feeMod: settings?.fee_mod,
              isKycDisabled: settings?.kyc_disabled,
              apiEndpoints: {
                stellar_toml_url: settings?.stellar_toml_url,
                rates_url: settings?.rates_url,
              },
              assetsParameters,
              status_callback_url: settings?.status_callback_url,
              apiVersion: 3,
              countries: settings?.countries,
              payment_methods: settings?.payment_methods,
              merchant_guid: settings?.merchant_guid,
              transactions_guid: transactionId,
            });
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        appStore.setIsAppLoading(false);
      }
    })();

  }, [
    appStore.getRouteApiVersion,
  ]);

  const renderFpfComponent = () => {
    const {
      psp_customer_id,
      token,
      transaction_auth_token,
      appId,
      mainBrandColor,
      amount,
      redirectUrl,
      type,
      txCallbackUrl,
      feeMod,
      isKycDisabled,
      apiEndpoints,
      assetsParameters,
      status_callback_url,
      apiVersion,
      tomlData,
      logo_url,
      countries,
      text_black_color,
      text_grey_color,
      bg_color,
      payment_methods,
      deposit_callback_url,
      withdraw_callback_url,
      asset_code,
      external_id,
      session_url,
      merchant_guid,
      transaction_guid,
    } = mainData;

    const data = {
      type: type,
      token: token,
      merchantToken: transaction_auth_token,
      txCallbackUrl: txCallbackUrl,
      amount: amount,
      redirectUrl: redirectUrl,
      mainBrandColor: mainBrandColor,
      backgroundColor: bg_color,
      textBlackColor: text_black_color,
      textGreyColor: text_grey_color,
      kyc: !isKycDisabled,
      appId: appId,
      customerId: psp_customer_id,
      feeMod: feeMod,
      apiEndpoints: apiEndpoints,
      currencyAssets: assetsParameters,
      statusCallbackUrl: status_callback_url,
      apiVersion: apiVersion,
      tomlData: tomlData,
      countries: countries,
      partnerLogo: logo_url,
      methodIds: payment_methods,
      depositCallbackUrl: deposit_callback_url,
      withdrawCallbackUrl: withdraw_callback_url,
      assetCode: asset_code,
      externalId: external_id || '',
      sessionUrl: session_url,
      merchantGuid: merchant_guid,
      transactionId: transaction_guid,
    };

    if (
      (psp_customer_id
        && token
        && transaction_auth_token
        && Object.values(apiEndpoints).length
        && Object.values(tomlData).length
      ) || apiVersion === 3
    ) {
      return (
        <FastPaymentFlow
          data={data}
        />
      );
    }

    return null;
  }

  return (
    <>
        {renderFpfComponent()}
    </>
  )
}

export default inject('appStore', 'paymentStore')(observer(PaymentComponent));
